.banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: calc(2 * var(--dex-spacing-1x));
  z-index: 2;

  display: flex;
  align-items: center;
  justify-content: center;
}

.green {
  background-color: var(--core-green-30-color);
}

.red {
  background-color: var(--core-red-30-color);
}

.purple {
  background-color: var(--core-purple-30-color);
}

.blue {
  background-color: var(--core-blue-30-color);
}

.content {
  position: relative;
}
.wrap:last-child {
  border: none;
}
.top {
  display: flex;
  align-items: center;
  gap: 8px;
}
.link {
  font-weight: 500;
  text-decoration: none;
  color: var(--core-emphasis-text-color);
}
.tag-wrap {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.tag {
  color: var(--core-text-20-color);
}

.pill {
  display: block;

  /* Ensures the market-pill doesn't move the page when it pops in */
  height: var(4 * calc(--dex-spacing-1x));
}

.filter-container {
  display: block;
  /* Ensures that the market button doesn't "pop in" and move the content in SSR scenarios */
  height: var(--filter-button-medium-size-minimum-height);
}

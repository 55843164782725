.switcher {
  position: fixed;
  bottom: 0;
  right: 0;
  user-select: none;
  display: flex;
  flex-direction: column;
  background-color: var(--core-surface-5-color);
  transition: all 0.25s ease;
}

.label {
  display: block;

  color: var(--core-purple-text-color);
}

.hitbox {
  --hitarea: 50px;

  position: absolute;
  top: calc(-1 * var(--hitarea));
  bottom: calc(-1 * var(--hitarea));
  left: calc(-1 * var(--hitarea));
  right: calc(-1 * var(--hitarea));
  z-index: -1;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.control {
  min-width: 80px;
}

.dialog {
  overflow-y: unset;
}

.admin-close {
  position: absolute;
  top: 4px;
  right: 4px;
}

.close-svg path {
  fill: black;
}
